import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';
import { LogType } from '@parashift/shared/types';

// Only numeric enums can have computed members
// So I had to convert the enums with translations into an object
const WorkflowStatus = {
  started: $localize `:@@common.started:Started` as 'started',
  in_progress: $localize `:@@common.in_progress:In progress` as 'in_progress',
  retry: $localize `:@@common.retry:Retry` as 'retry',
  done: $localize `:@@common.done:Done` as 'done',
  failed: $localize `:@@common.failed:Failed` as 'failed'
};
type WorkflowStatus = (typeof WorkflowStatus)[keyof typeof WorkflowStatus];
export { WorkflowStatus };

const WorkflowStep = {
  inbound: $localize `:@@common.inbound:Inbound` as 'inbound',
  inbound_processing: $localize `:@@common.inbound_processing:Inbound Processing` as 'inbound_processing',
  ocr: $localize `:@@common.ocr:OCR` as 'ocr',
  separation_instructions: $localize `:@@common.separation:Separation` as 'separation_instructions',
  separation_validation: $localize `:@@common.separation_validation:Separation Validation` as 'separation_validation',
  separation_qc: $localize `:@@common.separation_qc:Separation QC` as 'separation_qc',
  separation_processing: $localize `:@@common.separation_processing:Separation Processing` as 'separation_processing',
  classification: $localize `:@@common.classification:Classification` as 'classification',
  classification_validation: $localize `:@@common.classification_validation:Classification Validation` as 'classification_validation',
  classification_qc: $localize `:@@common.classification_qc:Classification QC` as 'classification_qc',
  extraction: $localize `:@@common.extraction:Extraction` as 'extraction',
  extraction_validation: $localize `:@@common.extraction_validation:Extraction Validation` as 'extraction_validation',
  extraction_qc: $localize `:@@common.extraction_qc:Extraction QC` as 'extraction_qc',
  outbound_processing: $localize `:@@common.outbound_processing:Outbound Processing` as 'outbound_processing',
  done: $localize `:@@common.done:Done` as 'done',
  qc: $localize `:@@common.qc:QC` as 'qc'
};
type WorkflowStep = (typeof WorkflowStep)[keyof typeof WorkflowStep];
export { WorkflowStep };

export enum WorkflowSteps {
  inbound = 1,
  inbound_processing,
  ocr,
  separation_instructions,
  separation_validation,
  separation_qc,
  separation_processing,
  classification,
  classification_validation,
  classification_qc,
  extraction,
  extraction_validation,
  extraction_qc,
  outbound_processing,
  done,
  qc,
}

export interface LogPlainModel {
  id: string;
  created_at: string;
  document_field_id: number;
  document_id: number;
  document_type_field_identifier: string;
  internal: boolean;
  job_id: string;
  message: string;
  target: string;
  tenant_id: number;
  version: string;
  workflow_status: WorkflowStatus;
  workflow_step: WorkflowStep;
}

@Model({ type: LogType })
export class Log extends BaseApiModel<LogPlainModel> {
  @Attribute()
  created_at: string;

  @Attribute()
  document_field_id: number;

  @Attribute()
  document_id: number;

  @Attribute()
  document_type_field_identifier: string;

  @Attribute()
  internal: boolean;

  @Attribute()
  job_id: string;

  @Attribute()
  message: string;

  @Attribute()
  target: string;

  @Attribute()
  tenant_id: number;

  @Attribute()
  version: string;

  @Attribute()
  workflow_status: WorkflowStatus;

  @Attribute()
  workflow_step: WorkflowStep;
}
